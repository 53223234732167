<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-card>
        <v-card-text>
          <v-tabs class="my-tabs" align-tabs="left">
            <v-tab> Wellhead </v-tab>
            <v-tab> Hanger </v-tab>

            <v-tab-item>
              <br />
              <v-row>
                <v-row
                  align="center"
                  justify="center"
                  style="font-weight: bold; margin-right: 60px"
                >
                  <v-col cols="12" sm="1" md="1"> </v-col>
                  <v-col cols="12" sm="2" md="2">Operation </v-col>
                  <v-col cols="12" sm="2" md="1">Opening turns</v-col>
                  <v-col cols="12" sm="2" md="1">Closing turns</v-col>

                  <v-col cols="12" sm="2" md="1">Initial pressure</v-col>
                  <v-col cols="12" sm="2" md="1">Final pressure</v-col>
                  <v-col cols="12" sm="2" md="1">Time</v-col>
                  <v-col cols="12" sm="2" md="2">Coment</v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-row
                      class="rowh2"
                      dense
                      align="center"
                      justify="center"
                      v-for="item in xtrees_list.filter((x) => x.type_id == 95)"
                      :key="item.component_id"
                    >
                      <v-col cols="12" sm="2" md="1">
                        <div style="font-weight: bold; margin-bottom: 20px">
                          <span>{{ item.label }}</span>
                        </div></v-col
                      >
                      <v-col cols="12" sm="2" md="2">
                        <v-select
                          style="padding-right: 8px"
                          v-model="item.operat"
                          @change="get_failures()"
                          outlined
                          return-object
                          hide-spin-buttons
                          dense
                          :label="item.component"
                          @input="changes"
                          :items="['Easy', 'Hard']"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" sm="2" md="1" align-self="center">
                        <v-text-field
                          align-self="center"
                          class="rowh"
                          hide-spin-buttons
                          autocomplete="off"
                          v-model="item.o_turns"
                          @change="get_failures()"
                          type="number"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="2" md="1">
                        <v-text-field
                          class="rowh"
                          return-object
                          autocomplete="off"
                          hide-spin-buttons
                          v-model="item.c_turns"
                          :disabled="item.o_turns == 0"
                          @change="get_failures()"
                          type="number"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="1">
                        <v-text-field
                          class="rowh"
                          autocomplete="off"
                          return-object
                          hide-spin-buttons
                          v-model="item.i_pressure"
                          @change="get_failures()"
                          type="number"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="2" md="1">
                        <v-text-field
                          class="rowh"
                          return-object
                          hide-spin-buttons
                          autocomplete="off"
                          v-model="item.f_pressure"
                          @change="get_failures()"
                          type="number"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" sm="1" md="1">
                        <div
                          style="
                            font-weight: bold;
                            margin-bottom: 20px;
                            margin-left: 20px;
                          "
                        >
                          <span>10</span>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="2" md="3">
                        <v-text-field
                          class="rowh"
                          hide-spin-buttons
                          return-object
                          autocomplete="off"
                          v-model="item.comment"
                          outlined
                          dense
                        >
                        </v-text-field>
                      </v-col> </v-row
                    ><br /><v-row class="font_style">
                      * Pression in {{ pressure_unit }} </v-row
                    ><v-row class="font_style">
                      * 0 turns= failed to close/open </v-row
                    ><br />
                  </v-col>
                </v-row>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <br />

              <v-row style="font-weight: bold; margin-left: 130px">
                <v-col cols="12" sm="2" md="1"></v-col>
                <v-col cols="12" sm="2" md="1" align="center"
                  >Initial pressure</v-col
                >
                <v-col cols="12" sm="2" md="1" align="center"
                  >Final pressure</v-col
                >
                <v-col cols="12" sm="2" md="1" align="center">Time</v-col>

                <v-col cols="12" sm="2" md="1" style="margin-left: 130px"
                  >Coment</v-col
                >
                <v-col
                  cols="12"
                  sm="2"
                  md="1"
                  style="margin-left: 130px"
                ></v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-row
                    class="rowh2"
                    dense
                    v-for="item in xtrees_list.filter((x) => x.type_id != 95)"
                    :key="item.id"
                  >
                    <v-col cols="12" sm="2" md="2">
                      <div style="font-weight: bold; margin-bottom: 20px">
                        <span>{{ item.label }}</span>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                      <v-text-field
                        class="rowh"
                        autocomplete="off"
                        return-object
                        hide-spin-buttons
                        v-model="item.i_pressure"
                        @change="get_failures()"
                        type="number"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="1">
                      <v-text-field
                        class="rowh"
                        return-object
                        hide-spin-buttons
                        autocomplete="off"
                        v-model="item.f_pressure"
                        @change="get_failures()"
                        type="number"
                        outlined
                        dense
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" sm="1" md="1">
                      <div
                        style="
                          font-weight: bold;
                          margin-bottom: 20px;
                          margin-left: 20px;
                        "
                      >
                        <span>10</span>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="2" md="4">
                      <v-text-field
                        style="padding-right: 8px"
                        v-model="item.comment"
                        outlined
                        return-object
                        dense
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="2" md="3">
                      <v-select
                        style="padding-right: 8px"
                        v-model="item.valelement"
                        outlined
                        multiple
                        label="Failed elements"
                        dense
                        :items="
                          elements.filter((x) => x.parent == item.cmp_code)
                        "
                        v-if="item.i_pressure != item.f_pressure"
                        item-value="cmp_code"
                        item-text="label"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 1">
                            <span>{{ item.label }}</span>
                          </v-chip>
                          <span
                            v-if="index === 1"
                            class="text-grey text-caption align-self-center"
                          >
                            ...
                          </span>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import allquery from "../graphql/WellTest/TestForm/allQuery.gql";
export default {
  components: {},
  props: {
    xtrees: Array,
    test_readonly: Boolean,
    failures_list: Array,
    editedItem: Object,
  },
  data() {
    return {
      kall: 0,
      xtrees_list: [],
      pressure_unit: "",
      all_failures_list: [],
      test_failures: [],
      barriers: [],
      well_failures: [],
      elements: [
        { cmp_code: "901", label: "TH Metal to meatl seal", parent: "9" },
        { cmp_code: "905", label: "TH Ring gasket", parent: "9" },
        { cmp_code: "906", label: "TH Cavity test port", parent: "9" },
        { cmp_code: "907", label: "TH Body seal", parent: "9" },

        { cmp_code: "1001", label: "Hanger neck seal", parent: "10" },
        { cmp_code: "1003", label: "Hanger ring gasket", parent: "10" },
        { cmp_code: "1004", label: "Hanger Cavity test port", parent: "10" },
        { cmp_code: "1005", label: "hanger Body seal", parent: "10" },

        { cmp_code: "1101", label: "Hanger neck seal", parent: "11" },
        { cmp_code: "1103", label: "Hanger ring gasket", parent: "11" },
        { cmp_code: "1104", label: "Hanger Cavity test port", parent: "11" },
        { cmp_code: "1105", label: "hanger Body seal", parent: "11" },

        { cmp_code: "1201", label: "Hanger neck seal", parent: "12" },
        { cmp_code: "1203", label: "Hanger ring gasket", parent: "12" },
        { cmp_code: "1204", label: "Hanger Cavity test port", parent: "12" },
        { cmp_code: "1205", label: "hanger Body seal", parent: "12" },
      ],
    };
  },

  mounted() {
    this.getdata();

    this.pressure_unit = this.$store.state.options[0].pressure;
    this.xtrees_list = Object.assign([], this.xtrees);
    this.well_failures = Object.assign([], this.failures_list);
    this.$emit("changes", this.xtrees_list, this.failures_list);
  },
  computed: {},
  watch: {},
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    async getdata() {
      let r = await this.$requette(allquery);
      if (r.ok) {
        this.all_failures_list = r.data.failures_list;
        this.barriers = r.data.wmf_list;
      }
    },
    valuechanged_open(item) {
      //fail to open
      if (item.o_turns) {
        if (item.o_turns == "0") {
          item.f_pressure = null;
          item.i_pressure = null;
          item.c_turns = null;
          this.kall++;
          this.set_falure(item, 188, 2);
        } else if (
          parseInt(item.o_turns) !=
          parseInt(item.v_turns ? item.v_turns : item.o_turns)
        ) {
          this.set_falure(item, 220, 2);
        }
      }
    },
    valuechanged_close(item) {
      if (item.c_turns) {
        if (item.c_turns == 0) this.set_falure(item, 189, 3);
        //fail to close
        else if (
          parseInt(item.c_turns) !=
          parseInt(item.v_turns ? item.v_turns : item.c_turns)
        ) {
          //partial close
          this.set_falure(item, 221, 3);
        }
      }
    },
    valuechanged_operat(item) {
      if (item.operat == "Hard") this.set_falure(item, 222, 4);
    },
    valuechanged_pressure(item) {
      if (item.i_pressure == 0) item.i_pressure = null;
      if (item.f_pressure == 0) item.f_pressure = null;
      if (item.i_pressure && item.f_pressure) {
        if (item.i_pressure != item.f_pressure) {
          if (Math.abs(item.i_pressure - item.f_pressure) >= item.pressure)
            this.set_falure(item, 186, 1);
          else if (Math.abs(item.i_pressure - item.f_pressure) < item.pressure)
            this.set_falure(item, 187, 1);
        }
      }
    },
    set_falure(item, failure, gid) {
      let fail = {
        id: null,
        state: 1,
        cmp_code: item.cmp_code,
        cmp_id: item.cmp_id,
        failure_id: failure,
        welltest_id: this.editedItem.id,
        g_id: gid,
        well_tubular_id: item.well_tubular_id,
        testtype: this.editedItem.testtype_id,
      };

      this.test_failures.push(
        this.$calc_single(this.barriers, this.all_failures_list, fail)
      );

      //this.$calc_single(this.barriers, this.failures_list, fail);
    },
    get_failures() {
      this.test_failures = [];
      // get actual failures
      this.xtrees_list.forEach((item) => {
        this.valuechanged_open(item);
        this.valuechanged_close(item);
        this.valuechanged_operat(item);
        this.valuechanged_pressure(item);
      });
      //calculate multiples
      let multiples = this.$get_multiples(
        this.well_failures,
        this.test_failures.filter((x) => x.cmp_code != "0" && x.failure_id > 0),
        this.barriers,
        this.all_failures_list,
        this.editedItem.id
      );

      // add multiples
      if (multiples)
        if (multiples.length > 0) {
          var max = multiples.reduce(function (prev, current) {
            if (+current.failure_taux < +prev.failure_taux) {
              return current;
            } else {
              return prev;
            }
          });
          this.test_failures.push(max);
        }
      // if (multiples) multiples.forEach((m) => this.test_failures.push(m));
      this.$emit("changes", this.xtrees_list, this.test_failures);
    },
    changes() {
      this.$emit("changes", this.xtrees_list, this.test_failures);
    },
  },
};
</script>
<style>
.rowh0 {
  width: 100% !important;
  height: 45px !important;
}
</style>
